
import { computed, ref, watch } from 'vue';

// icons
import { compass, informationCircle, location, call, print, mail, documentText,
        scan, add, qrCodeOutline, close, walk, notificationsOutline, flame,
        trophyOutline, flowerOutline, thumbsUp, logoWhatsapp, paw, receiptOutline, } from 'ionicons/icons';

// components
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import '@ionic/vue/css/ionic-swiper.css';

import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { IonicSlides, IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonCol, IonRow, IonSpinner,
        IonSegment, IonSegmentButton, IonLabel, IonAvatar,
        IonButtons, IonButton, IonIcon, IonBadge,
        IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonChip, IonModal,
        IonBackButton,
        onIonViewDidEnter, } from '@ionic/vue';
import LogoImg from "@/components/LogoImg.vue";
import Slides from "@/components/slides/Slides.vue";
import InfoCardList from "@/components/info/InfoCardList.vue";
import SectionHeader from "@/components/SectionHeader.vue";
import DogWalkingModal from '@/components/modals/DogWalkingModal.vue';
import PetFormModal from '@/components/modals/PetFormModal.vue';
import StickerPreQuestionModal from '@/components/modals/StickerPreQuestionModal.vue';

// services
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import config from '@/config';
import { utils } from '@/composables/utils';

import { Browser } from '@capacitor/browser';
import { Preferences } from '@capacitor/preferences';

export default {
  name: 'HomePage',
  components: { Swiper, SwiperSlide, IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
                IonGrid, IonCol, IonRow, IonSpinner,
                IonSegment, IonSegmentButton, IonLabel, IonAvatar,
                IonButtons, IonButton, IonIcon, IonBadge,
                IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonChip, IonModal,
                IonBackButton,
                LogoImg, Slides, InfoCardList, SectionHeader, },
  setup() {
    // methods
    const { t, locale, } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { openInAppBrowser, getLocalizedStr, openModal } = utils();
    const OPENED_APP_KEY = "openedAppBefore";

    // state variables
    const currUser = computed(() => store.state.user);
    const userLoggedIn = computed(() => store.state.loggedIn);
    const loadingUserData = computed(() => store.state.loadingAppUserData);
    const loadingAppPublicData = computed(() => store.state.loadingAppPublicData);
    const settings = computed(() => store.state.settings);
    const homeBanners = computed(() => store.getters.homeBanners);
    const topBanners = computed(() => store.getters.topBanners);
    const userPets = computed(() => store.getters.orderedUserPets);
    const userStickers = computed(() => store.getters.orderedUserStickers);
    const userOrders = computed(() => store.state.userOrders);
    const placeTypes = computed(() => store.getters.placeTypesWithoutAll);
    const infos = computed(() => store.state.infos);
    const homeOpenPetFormModal = computed(() => store.state.homeOpenPetFormModal);
    const homeOpenStickerPreQuestionModal = computed(() => store.state.homeOpenStickerPreQuestionModal);

    const isAboutUsModalOpened = ref(false);

    // Video
    let touchStartTime = null;
    const videoPlayer = ref(null);
    const videoSlides = ref();
    const setSwiperInstance = (swiper) => videoSlides.value = swiper;

    const openDogWalkingModal = async (title: any, qualifRows: any, settings: any) => {
      openModal(DogWalkingModal, {});
    };

    const onSegmentChanged = (e: any) => {
      if (e.target.value) {
        router.push({ name: 'PlaceOverviewPage', state: { type: e.target.value, parentPath: '/home' } }); // go to place page and pre-select type
      }
    }

    const openBannerLink = (link) => {
      if (link) {
        if (link.startsWith("/")) router.push(link);
        else openInAppBrowser(link);
      }
    }

    const startTimer = () => {
      touchStartTime = (new Date()).valueOf();
    }
    const checkTapVideo = (e) => {
      if (((new Date()).valueOf() - touchStartTime) < 100) {
        const videoEl = e.target;
        if (videoEl.paused == true) videoEl.play();
        else videoEl.pause();
      }
    }
    const onVideoEnded = (e) => {
      e.target.webkitExitFullScreen();
      videoSlides.value.autoplay.start();
    }

    const openBrowser = async (url: any) => {
      await Browser.open({ url, toolbarColor: config.primaryColor });
    }

    watch(loadingAppPublicData, (currLoading) => { 
      if (currLoading == false) {
        Preferences.migrate();
        Preferences.get({ key: OPENED_APP_KEY }).then(ret => {
          if (ret.value == null) {
            setTimeout(() => {
              // videoPlayer.value.play(); // not working well
              const videoEl: any = document.querySelector("#video-player");
              videoEl.play(); // play video on first time entering app
            }, 500);

            Preferences.set({
              key: OPENED_APP_KEY,
              value: "1",
            });
          }
          Preferences.removeOld();
        });
      }
    });

    onIonViewDidEnter(() => {
      //document.querySelector(`#top-anchor`).scrollIntoView(true);
      if (userLoggedIn.value == true) {
        if (homeOpenPetFormModal.value == true) {
          setTimeout(() => {
            openModal(PetFormModal, { });
            store.commit('setHomeOpenPetFormModal', false);
          }, 1000);
        } else if (homeOpenStickerPreQuestionModal.value == true) {
          setTimeout(() => {
            openModal(StickerPreQuestionModal, { });
            store.commit('setHomeOpenStickerPreQuestionModal', false);
          }, 1000);
        }
      }
    });

    return {
      // methods
      t, openDogWalkingModal,
      onSegmentChanged, getLocalizedStr, openInAppBrowser, openBrowser,
      startTimer, checkTapVideo, onVideoEnded,
      setSwiperInstance, openBannerLink,

      // icons
      compass, informationCircle, call, print, mail,
      documentText, scan, add, qrCodeOutline, close, walk,
      location, notificationsOutline, trophyOutline, flowerOutline, thumbsUp,
      flame, logoWhatsapp, paw, receiptOutline,
      
      // variables
      locale,
      currUser, userLoggedIn,
      homeBanners, topBanners,
      loadingUserData,
      settings, loadingAppPublicData,
      userPets, userStickers, userOrders,
      placeTypes,
      isAboutUsModalOpened,
      infos,
      videoPlayer, videoSlides,

      // swiper modules
      modules: [Pagination, Autoplay, IonicSlides],
    }
  },
}
